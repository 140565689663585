import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import PropertiesBar from "./PropertiesBar";
import breakpoints from "../../styles/breakpoints";
import Container from "../Core/Container";

const StaticSection = styled.section`
  padding:  1.5rem 0 3rem;

  @media only screen and (min-width: ${breakpoints.lg}) {
    padding:  2.5rem 0 5rem;
  }`;

const Content = styled.div`
  padding-top: 1.5rem;

  p a, a {
    color: var(--secondary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    padding-right: ${props => props.propertiesHeading && "1.5rem"};
  }
`;

const StaticContent = (props) => {
    const copy = props._rawCopy;
    const propertiesHeading = props.propertiesBar.heading;
    const {properties} = props.propertiesBar;
    const propertiesValue = properties.length > 0;

    const serializers = {
      marks: {
        link: ({mark, children}) => {
          const { href } = mark
          return <a href={href} target="_blank" rel="noopener">{children}</a>
        },
        id: ({mark, children}) => {
          const { id } = mark
          return <span id={id}>{children}</span>
        },
      }
    }

    return (
      <StaticSection>
        <Container>
          <Row>
            <Col lg={propertiesValue ? 7 : 10} lgOffset={1}>
              <Content propertiesHeading={propertiesHeading}>
                <BlockContent blocks={copy} serializers={serializers} />
              </Content>
            </Col>
            {(propertiesHeading && propertiesValue) && (
            <Col lg={3}>
              <PropertiesBar properties={properties} propertiesHeading={propertiesHeading} />
            </Col>
            )}
          </Row>
        </Container>
      </StaticSection>
    );
};

export default StaticContent;