import React, {useState} from "react";
import {Link} from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const PropertiesBarContainer = styled.div`
  padding-top: 1.5rem;

  @media only screen and (min-width: ${breakpoints.lg}) {
    position: sticky;
    top: 0;
  }
`;

const PropertiesBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media only screen and (min-width: ${breakpoints.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

const Heading = styled.h3`
  padding-bottom: 1.125rem;
  font-size: 1.25rem;

  @media only screen and (min-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }
`;

const PropCard = styled(Link)`
  height: 100%;
  width: 100%;
  position: relative;
  text-decoration: none;
  color: var(--white);
  box-sizing: border-box;
  display: block;

  @media only screen and (min-width: ${breakpoints.sm}) {
    width: calc(33.333% - 7px);
    box-sizing: border-box;
    margin-bottom: 0;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const PropTitle = styled.p`
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  font-size: 1.25rem;
  z-index: 9;
  padding: 1rem;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);

  @media only screen and (min-width: ${breakpoints.lg}) {
    opacity: ${props => props.isHovered ? 1 : 0};
    transition: .2s;
  }
`;

const Overlay = styled.div`
  background: var(--codGrey);;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  transition: .2s;

  @media only screen and (min-width: ${breakpoints.lg}) {  
    opacity: ${props => props.isHovered ? 0.2 : 0};
  }
`;


const PropertiesBar = ({properties, propertiesHeading}) => {
    const [isHovered, setHover] = useState(null);

    return (
      <PropertiesBarContainer>
        <Heading>{propertiesHeading}</Heading>
        <PropertiesBlock>
          {properties.map((property, i)=> {
                return (
                  <PropCard
                    to={property.slug.current}
                    aria-label={property.title}
                    key={i}
                    onMouseEnter={() => setHover(i)}
                    onMouseLeave={() => setHover(null)}
                  >
                    <Img fluid={property.image.image.asset.fluid} alt={property.name} />
                    <PropTitle isHovered={isHovered === i}>{property.name}</PropTitle>
                    <Overlay isHovered={isHovered === i} />
                  </PropCard>
                );
            })}
        </PropertiesBlock>
      </PropertiesBarContainer>
    );
};

export default PropertiesBar;